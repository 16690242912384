import gql from "graphql-tag";

export const CREATE_TRAVEL_TIP = gql`
        mutation createTravelTip(
            $campaignId:ID,
            $titlePicture:ID,
            $theme:String,
            $hashtags:String,
            $purchasable: Boolean,
            $top: Boolean,
            $originalLanguage: String!,
            $localizations: [TravelTipLocalizationInput]
,
            $tagIds: [Int]
        ) {
            createTravelTip(
                campaignId: $campaignId,
                titlePicture: $titlePicture,
                theme: $theme,
                hashtags: $hashtags,
                purchasable: $purchasable,
                top: $top,
                originalLanguage: $originalLanguage,
                localizations: $localizations,
                tagIds: $tagIds
            ) {
                id
            }
        }`;

    export const UPDATE_TRAVEL_TIP = gql`
        mutation updateTravelTip(
            $travelTipId:Int!,
            $campaignId:ID,
            $titlePicture:ID,
            $hashtags:String,
            $purchasable:Boolean,
            $theme:String,
            $originalLanguage: String!,
            $localizations: [TravelTipLocalizationInput]
            $tagIds: [Int]
        ) {
            updateTravelTip(
                id:$travelTipId,
                campaignId:$campaignId,
                hashtags: $hashtags,
                titlePicture: $titlePicture,
                purchasable:$purchasable,
                theme:$theme,
                originalLanguage: $originalLanguage,
                localizations: $localizations
                tagIds: $tagIds
            ) {
                id
            }
        }`;

export const BUY_TRAVEL_TIP = gql`
    mutation buyTravelTip(
        $id:Int!,
        $userId:ID!,
    ) {
        buyTravelTip(
            id:$id,
            userId:$userId,
        ) {
            id
            title
        }
    }`;

export const DELETE_IN_PROGRESS_TRAVEL_TIP = gql`
    mutation deleteInProgressTravelTip($travelTipId:Int!) {
        deleteInProgressTravelTip(travelTipId: $travelTipId)
    }`;

export const DElETE_TRAVEL_TIP = gql`
    mutation deleteTravelTip($travelTipId: Int!) {
        deleteTravelTip(travelTipId: $travelTipId)
    }`;

export const PUBLISH_TRAVEL_TIP = gql`
    mutation publishTravelTip($travelTipId: Int!) {
        publishTravelTip(travelTipId: $travelTipId)
    }`

export const HIDE_TRAVEL_TIP = gql`
    mutation hideTravelTip($travelTipId: Int!) {
        hideTravelTip(travelTipId: $travelTipId)
    }`

export const IN_PROGRESS_TRAVEL_TIP = gql`
    mutation inProgressTravelTip($travelTipId: Int!) {
        inProgressTravelTip(travelTipId: $travelTipId)
    }`
    
export const TO_REWORK__TRAVEL_TIP = gql`
    mutation toReworkTravelTip($travelTipId: Int!) {
        toReworkTravelTip(travelTipId: $travelTipId)
    }`

export const TO_CONTROL_TRAVEL_TIP = gql`
    mutation toControlTravelTip($travelTipId: Int!) {
        toControlTravelTip(travelTipId: $travelTipId)
    }`

export const TO_APPROVE_TRAVEL_TIP = gql`
    mutation toApproveTravelTip($travelTipId: Int!) {
        toApproveTravelTip(travelTipId: $travelTipId)
    }`

export const DECLINE_TRAVEL_TIP = gql`
    mutation declineTravelTip($travelTipId: Int!) {
        declineTravelTip(travelTipId: $travelTipId)
    }`

export const TOP_TRAVEL_TIP = gql`
    mutation topTravelTip($travelTipId:Int!) {
        topTravelTip(travelTipId: $travelTipId)
    }`

export const UNTOP_TRAVEL_TIP = gql`
    mutation untopTravelTip($travelTipId:Int!) {
        untopTravelTip(travelTipId: $travelTipId)
    }`

export const LIKE_TRAVEL_TIP = gql`mutation likeTravelTip($travelTipId: Int!) {
    likeTravelTip(travelTipId: $travelTipId)
}`;

export const UNLIKE_TRAVEL_TIP = gql`mutation unlikeTravelTip($travelTipId: Int!) {
    unlikeTravelTip(travelTipId: $travelTipId)
}`;