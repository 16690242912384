import { CREATE_TRAVEL_TIP, UPDATE_TRAVEL_TIP, DElETE_TRAVEL_TIP, BUY_TRAVEL_TIP, PUBLISH_TRAVEL_TIP, HIDE_TRAVEL_TIP, TOP_TRAVEL_TIP, UNTOP_TRAVEL_TIP, IN_PROGRESS_TRAVEL_TIP, TO_REWORK__TRAVEL_TIP, TO_CONTROL_TRAVEL_TIP, TO_APPROVE_TRAVEL_TIP, DECLINE_TRAVEL_TIP, DELETE_IN_PROGRESS_TRAVEL_TIP, LIKE_TRAVEL_TIP, UNLIKE_TRAVEL_TIP} from "../api/graphql/mutation/TravelTipMutation";
import { apolloClient } from "@/api/apolloClient";
import { FILTERED_TRAVEL_TIPS, TRAVEL_TIP_BY_ID_ADMIN } from "../api/graphql/query/TravelTipQuery";

export default {
    createTravelTip: function (form) {
        console.log("Start to creating travel Tip with title " + form.title);
        const {titlePicture, hashtags, campaignId, purchasable, theme, top, originalLanguage, localizations, tagIds} = form;
        return apolloClient.mutate({
            mutation: CREATE_TRAVEL_TIP,
            variables: {
                campaignId,
                titlePicture,
                hashtags,
                theme,
                purchasable,
                top,
                tagIds,
                originalLanguage,
                localizations
            },
        }).then((result) => {
            console.log("Travel Tip created: ", result);
            return result;
        });
    },

    updateTravelTip: function (travelTipId, form) {
        console.log("Updating travel Tip with id " + travelTipId);
        const {titlePicture, hashtags, campaignId, purchasable, theme, originalLanguage, localizations, tagIds} = form;
        return apolloClient.mutate({
            mutation: UPDATE_TRAVEL_TIP,
            variables: {
                travelTipId,
                campaignId,
                titlePicture,
                hashtags,
                theme,
                purchasable,
                originalLanguage,
                localizations,
                tagIds
            }
        }).then((result) => {
            console.log("Travel Tip updated: ", result);
            return result;
        });
    },

    deleteInProgressTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: DELETE_IN_PROGRESS_TRAVEL_TIP,
            variables: {
                travelTipId
            },
        }).then((result) => {
            console.log("TravelTip deleted: ", result);
            return result;
        });
    },

    deleteTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: DElETE_TRAVEL_TIP,
            variables: {
                travelTipId
            },
        }).then((result) => {
            console.log("TravelTip deleted: ", result);
            return result;
        });
    },

    publishTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: PUBLISH_TRAVEL_TIP,
            variables: {
                travelTipId
            },
            update: async (store) => {
                let data = await store.readQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)}});
                data = {...data, travelTip: {...data.travelTip, state: "PUBLISHED"}}
                store.writeQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data})
            }
        }).then((result) => {
            console.log("TravelTip published: ", result);
            return result;
        });
    },

    hideTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: HIDE_TRAVEL_TIP,
            variables: {
                travelTipId
            },
            update: async (store) => {
                let data = await store.readQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)}});
                data = {...data, travelTip: {...data.travelTip, state: "NOT_PUBLISHED"}}
                store.writeQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data})
            }
        }).then((result) => {
            console.log("TravelTip hidden: ", result);
            return result;
        });
    },

    inProgressTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: IN_PROGRESS_TRAVEL_TIP,
            variables: {
                travelTipId
            },
        }).then((result) => {
            console.log("TravelTip was successfully set to IN_PROGRESS: ", result);
            return result;
        });
    },

    toReworkTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: TO_REWORK__TRAVEL_TIP,
            variables: {
                travelTipId
            },
            update: async (store) => {
                let data = await store.readQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)}});
                data = {...data, travelTip: {...data.travelTip, state: "TO_REWORK"}}
                store.writeQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data})
            }
        }).then((result) => {
            console.log("TravelTip was successfully set to TO_REWORK: ", result);
            return result;
        });
    },
    toControlTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: TO_CONTROL_TRAVEL_TIP,
            variables: {
                travelTipId
            },
            update: async (store) => {
                let data = await store.readQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)}});
                data = {...data, travelTip: {...data.travelTip, state: "TO_CONTROL"}}
                store.writeQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data})
            }
        }).then((result) => {
            console.log("TravelTip was successfully set to TO_CONTROL: ", result);
            return result;
        });
    },

    toApproveTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: TO_APPROVE_TRAVEL_TIP,
            variables: {
                travelTipId
            },
            update: async (store) => {
                let data = await store.readQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)}});
                data = {...data, travelTip: {...data.travelTip, state: "TO_APPROVE"}}
                store.writeQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data})
            }
        }).then((result) => {
            console.log("TravelTip was successfully set TO_APPROVE: ", result);
            return result;
        });
    },
    declineTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: DECLINE_TRAVEL_TIP,
            variables: {
                travelTipId
            },
            update: async (store) => {
                let data = await store.readQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: parseInt(travelTipId)}});
                data = {...data, travelTip: {...data.travelTip, state: "DECLINED"}}
                store.writeQuery({query: TRAVEL_TIP_BY_ID_ADMIN, variables: {travelTipId: travelTipId}, data})
            }
        }).then((result) => {
            console.log("TravelTip declined: ", result);
            return result;
        });
    },

    topTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: TOP_TRAVEL_TIP,
            variables: {
                travelTipId
            },
         update: (store, {data: {topTravelTip}}) => {
            console.log(topTravelTip)}
        })
        .then((result) => {
            console.log("TravelTip topped: ", result);
            return result;
        });
    },

    untopTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: UNTOP_TRAVEL_TIP,
            variables: {
                travelTipId
            },
        }).then((result) => {
            console.log("TravelTip untopped: ", result);
            return result;
        });
    },

    buyTravelTip: function (id, userId) {
        return apolloClient.mutate({
            mutation: BUY_TRAVEL_TIP,
            variables: {
                id,
                userId
            },
        }).then((result) => {
            console.log("Travel tip purchased: ", result);
            return result;
        });

    },
    likeTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: LIKE_TRAVEL_TIP,
            variables: {
                travelTipId,
            },
        }).then((result) => {
            console.log("Travel tip liked: ", result);
            return result;
        });
    },
    unlikeTravelTip: function (travelTipId) {
        return apolloClient.mutate({
            mutation: UNLIKE_TRAVEL_TIP,
            variables: {
                travelTipId,
            },
        }).then((result) => {
            console.log("Travel tip unliked: ", result);
            return result;
        });
    }
}
